



































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class BilletCode extends Vue {
  @Prop({ required: true }) code!: string;
  @Prop({ required: true }) url!: string;

  downloadBillet() {
    window.open(`${this.url}`, '_blank');
  }

  copyText(): void {
    const input = this.$refs.inputCode as HTMLInputElement;
    input.select();
    document.execCommand('copy');

    this.$store.dispatch('Toast/setToast', {
      status: 'info',
      text: 'Link copiado para a área de transferência!',
    });
  }
}
