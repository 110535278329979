





















































import {
  Component, Vue,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ProgressPayment from '@/pages/Shopping/components/ProgressPayment/index.vue';
import BoxPaymentPrice from '@/pages/Shopping/components/BoxPaymentPrice/BoxPaymentPrice.vue';
import BilletCode from '@/pages/Shopping/components/BilletCode/BilletCode.vue';
import BoxPaymentInstruction from '@/pages/Shopping/components/BoxPaymentInstruction/BoxPaymentInstruction.vue';
import PaymentBilletLoading from '@/pages/Shopping/components/PaymentPixBilletLoading/index.vue';

import { INSTRUCTIONS_BILLET } from '@/pages/Shopping/constants/instructions';
import { METHOD_BY_BILLET, METHOD_BY_PIX, PAYMENT_STATUS } from '@/pages/Shopping/constants';

import { PaymentSuccess } from '@/pages/Shopping/interfaces/IPayment';

import PaymentService from '@/services/Payment/PaymentService';

const INDEX_TAB_MENU_PROFILE_PLAN = 2;

@Component({
  components: {
    PageHeader,
    Footer,
    ProgressPayment,
    BoxPaymentPrice,
    BilletCode,
    BoxPaymentInstruction,
    PaymentBilletLoading,
    FeedbackUser,
  },
})
export default class PaymentBillet extends Vue {
  private INSTRUCTIONS_BILLET = INSTRUCTIONS_BILLET;
  private isLoading = false;
  private paymentData: PaymentSuccess | null = null;

  private PaymentService = new PaymentService();

  async created() {
    await this.getPayment();
    this.validatePaymentMethod();
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getPayment() {
    try {
      this.isLoading = true;

      const [payment]: PaymentSuccess[] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS.PENDING,
      });

      if (payment) {
        this.paymentData = payment;
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao retornar os dados do pagamento.',
        status: 'error',
      });
      console.error({ error });
    } finally {
      this.isLoading = false;
    }
  }

  validatePaymentMethod() {
    if (!this.paymentData || this.paymentData.payment.method === METHOD_BY_BILLET) return;

    if (this.paymentData.payment.method === METHOD_BY_PIX) {
      this.redirectToPaymentByPix();
    }
  }

  redirectToPaymentByPix() {
    const { idPurchase } = this.$route.params;

    this.$router.push({
      name: 'RenewPaymentPix',
      params: {
        idPurchase,
      },
    });
  }

  redirectPagePlan() {
    this.$store.commit('setTabProfile', INDEX_TAB_MENU_PROFILE_PLAN);

    return '/profile';
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'plano', to: this.redirectPagePlan() },
      { title: 'Pagamento', to: null },
      { title: 'Boleto', to: null },
    ]);
  }
}
